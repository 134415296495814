exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-download-copy-js": () => import("./../../../src/pages/download copy.js" /* webpackChunkName: "component---src-pages-download-copy-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-youtube-to-mp-3-js": () => import("./../../../src/pages/youtube-to-mp3.js" /* webpackChunkName: "component---src-pages-youtube-to-mp-3-js" */),
  "component---src-pages-youtube-to-mp-4-js": () => import("./../../../src/pages/youtube-to-mp4.js" /* webpackChunkName: "component---src-pages-youtube-to-mp-4-js" */)
}

